import React from 'react';
import './App.css';

import { Button, Card, CardActionArea, CardContent, CardMedia, CssBaseline, Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    alignContent: 'center',
    minHeight: 250,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  media: {
    minHeight: 200,
  },
  box: {
    padding: 10,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
  },
  boxCenter: {
    position: 'absolute',
    left: '1%',
    bottom: '1%',
    right: '1%',
    alignContent: 'center',
    // msTransform: translateY('-50%'),
    // transform: translateY('-50%'),
  },
  copyright: {
    position: 'fixed',
    right: 10,
    bottom: 0,
  }
}));

export const App: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={4} md={4} className={classes.image}>
        <Card className={classes.boxCenter}>
          <CardContent>
            <Typography gutterBottom variant="h3" component="h2" color="primary">
              SmaWork
              </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              仕事でより創造的に！細かい作業にはシステムに！
              </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
        <Grid container>
        <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://v-space.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/v-space.jpeg"
                    title="V-Space"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      V-Space
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Virtual Coworking Space
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      仮想の共同仕事場
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://chatops.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/chat.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      ChatOps.jp
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      コミュニケーションツール
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      チームのミーティング通話・タスク管理
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://fileplus.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/fima.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      FILEプラス
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      セキュアなファイル共有
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      機密文書管理・証明
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://coma.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/coma.png"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Coma(コマ)
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      ノーコードでデータ連携
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      iPaaS型のクラウドサービス
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://recruit.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/recruit.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Recruit
                  </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      ITエンジニア求人
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      人材紹介
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://matter.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/matter.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Matter
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      案件・売上管理
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      請求・支払管理
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://sales.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/sales.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Sales
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      営業管理
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      自動メール配信
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.box}>
              <Link href="https://evaluation.smawork.jp" color="inherit">
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image="/images/talent.jpg"
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Evaluation
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      タレント評価
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      キャリア・教育コンテツ
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>

        </Grid>

        <Typography className={classes.copyright}>Copyright © NAL Japan 2021</Typography>
      </Grid>
    </Grid>
  );
}

export default App;
